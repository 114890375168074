import * as React from 'react';

import '../../components/style.scss';
import { Header } from '../../components/Header';
import { NavBar } from '../../components/NavBar';
import { Column } from '../../components/Column';

export interface SecondGradeProps {}

export class SecondGrade extends React.Component<SecondGradeProps> {
  render() {
    return (
      <div>
        <Header title="2nd Grade" />

        <NavBar page="2nd-grade" />

        <div className="container-fluid button-container">
          <div className="row">
            <Column title="Rules and Procedures" />
            <Column title="Typing">
              <div>
                <a href="https://www.dancemattypingguide.com/dance-mat-typing/level1_stage1.html" target="_blank">
                  Home Row
                </a>
              </div>
              <div>
                <a href="https://www.dancemattypingguide.com/dance-mat-typing/level1_stage2.html" target="_blank">
                  E and I
                </a>
              </div>
              <div>
                <a href="https://www.dancemattypingguide.com/dance-mat-typing/level1_stage3.html" target="_blank">
                  R and U
                </a>
              </div>
              <div>
                <a href="https://www.dancemattypingguide.com/dance-mat-typing/level2_stage4.html" target="_blank">
                  T and Y
                </a>
              </div>
              <div>
                <a href="https://www.dancemattypingguide.com/dance-mat-typing/level2_stage5.html" target="_blank">
                  W and O
                </a>
              </div>
              <div>
                <a href="https://www.dancemattypingguide.com/dance-mat-typing/level2_stage6.html" target="_blank">
                  Q and P
                </a>
              </div>
              <div>
                <a href="https://www.dancemattypingguide.com/dance-mat-typing/level3_stage7.html" target="_blank">
                  V and M
                </a>
              </div>
              <div>
                <a href="https://www.dancemattypingguide.com/dance-mat-typing/level3_stage8.html" target="_blank">
                  B and N
                </a>
              </div>
              <div>
                <a href="https://www.dancemattypingguide.com/dance-mat-typing/level3_stage9.html" target="_blank">
                  C and comma
                </a>
              </div>
              <div>
                <a href="https://www.dancemattypingguide.com/dance-mat-typing/level4_stage10.html" target="_blank">
                  X and Z
                </a>
              </div>
              <div>
                <a href="https://www.dancemattypingguide.com/dance-mat-typing/level4_stage11.html" target="_blank">
                  / and .
                </a>
              </div>
              <div>
                <a href="https://www.dancemattypingguide.com/dance-mat-typing/level4_stage12.html" target="_blank">
                  Shift Keys
                </a>
              </div>
              <div>
                <a href="https://10fastfingers.com/typing-test/english">Typing Test</a>
              </div>
            </Column>
            <Column title="Google">
              <div>
                <a
                  href="https://docs.google.com/presentation/d/18W3mSM0CG7yX4L4M4sivUsJl0HUheKm-Xk8YTJ6tWEE/copy"
                  target="_blank"
                >
                  Google Slides Intro
                </a>
              </div>
            </Column>
            <Column title="Internet Safety" />
            <Column title="Projects" />
            <Column title="Bonus" />
          </div>
        </div>
      </div>
    );
  }
}
