import * as React from 'react';

import '../../components/style.scss';
import { Header } from '../../components/Header';
import { BigButton } from '../../components/BigButton';
import { NavBar } from '../../components/NavBar';

export interface KinderProps {}

export class Kinder extends React.Component<KinderProps> {
  render() {
    return (
      <div>
        <Header title="Kinder" />

        <NavBar page="kinder" />

        <BigButton number="1" title="Getting Started" />
      </div>
    );
  }
}
