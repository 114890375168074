import * as React from 'react';
import styled from 'styled-components';

interface HeaderProps {
  title: string;
  subtitle?: string;
}

const HeaderDiv = styled.div`
  padding: 20px;
`;

const Title = styled.h1`
  color: #848ce5;
`;

const Heading = styled.p`
  font-weight: bold;
`;

export class Header extends React.Component<HeaderProps> {
  render() {
    return (
      <HeaderDiv>
        <Title>{this.props.title}</Title>
        {this.subtitleHeading()}
      </HeaderDiv>
    );
  }

  subtitleHeading() {
    if (!this.props.subtitle) {
      return;
    }

    return <Heading>{this.props.subtitle}</Heading>;
  }
}
