import * as React from 'react';
import styled from 'styled-components';

interface ColumnProps {
  title: string;
}

const ColumnHeader = styled.div`
  font-weight: bold;
  font-size: 120%;
  color: #454589;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export class Column extends React.Component<ColumnProps> {
  render() {
    return (
      <div className="col-sm">
        <ColumnHeader>{this.props.title}</ColumnHeader>
        <div>{this.props.children}</div>
      </div>
    );
  }
}
