import * as React from 'react';
import { Nav, Navbar, NavItem } from 'reactstrap';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

interface NavBarProps {
  page: string;
}

const StyledNavbar = styled(Navbar)`
  background-color: #e3f2fd;
`;

export class NavBar extends React.Component<NavBarProps> {
  render() {
    return (
      <StyledNavbar expand light>
        <div>
          <Nav navbar>
            <NavItem active={this.props.page === 'home'}>
              <Link className="nav-link" to="/">
                Home
              </Link>
            </NavItem>
            <NavItem active={this.props.page === 'kinder'}>
              <Link className="nav-link" to="/grade/kinder">
                Kinder
              </Link>
            </NavItem>
            <NavItem active={this.props.page === '1st-grade'}>
              <Link className="nav-link" to="/grade/1st-grade">
                1st Grade
              </Link>
            </NavItem>
            <NavItem active={this.props.page === '2nd-grade'}>
              <Link className="nav-link" to="/grade/2nd-grade">
                2nd Grade
              </Link>
            </NavItem>
            <NavItem active={this.props.page === '3rd-grade'}>
              <Link className="nav-link" to="/grade/3rd-grade">
                3rd Grade
              </Link>
            </NavItem>
          </Nav>
        </div>
      </StyledNavbar>
    );
  }
}
