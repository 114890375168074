import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import { Main } from './pages/Main';
import { BadRoute } from './pages/BadRoute';
import { Kinder } from './pages/grades/Kinder';
import { FirstGrade } from './pages/grades/FirstGrade';
import { SecondGrade } from './pages/grades/SecondGrade';
import { ThirdGrade } from './pages/grades/ThirdGrade';

import 'bootstrap/dist/css/bootstrap.min.css';

export class App extends React.Component {
  render() {
    return (
      <Switch>
        <Route exact path="/" component={Main} />
        <Route path="/grade/kinder" component={Kinder} />
        <Route path="/grade/1st-grade" component={FirstGrade} />
        <Route path="/grade/2nd-grade" component={SecondGrade} />
        <Route path="/grade/3rd-grade" component={ThirdGrade} />
        <Route path="*" component={BadRoute} />
      </Switch>
    );
  }
}
