import * as React from 'react';

import '../components/style.scss';
import { Header } from '../components/Header';
import { NavBar } from '../components/NavBar';

export interface MainProps {}

export class Main extends React.Component<MainProps> {
  render() {
    return (
      <div>
        <Header title="Ms. Andrea Land" subtitle="Welcome to my website!" />

        <NavBar page="home" />
      </div>
    );
  }
}
