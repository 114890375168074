import * as React from 'react';
import styled from 'styled-components';

const beesAndHoneyImage = require('./bees-and-honey.png');
const bubbleImage = require('./bubble.png');
const firetruckPuzzleImage = require('./firetruck-puzzle.png');
const magicPaintImage = require('./magic-paint.png');
const mouseTutorialImage = require('./mouse-tutorial.png');
const oceanPuzzleImage = require('./ocean-puzzle.png');
const trainPuzzleImage = require('./train-puzzle.png');
const truckPuzzleImage = require('./truck-puzzle.png');

const SizedImage = styled.img`
  width: 200px;
  display: block;
`;

export class BeesAndHoney extends React.Component {
  render() {
    return <SizedImage src={beesAndHoneyImage} />;
  }
}

export class Bubble extends React.Component {
  render() {
    return <SizedImage src={bubbleImage} />;
  }
}

export class FireTruckPuzzle extends React.Component {
  render() {
    return <SizedImage src={firetruckPuzzleImage} />;
  }
}

export class MagicPaint extends React.Component {
  render() {
    return <SizedImage src={magicPaintImage} />;
  }
}

export class MouseTutorial extends React.Component {
  render() {
    return <SizedImage src={mouseTutorialImage} />;
  }
}

export class OceanPuzzle extends React.Component {
  render() {
    return <SizedImage src={oceanPuzzleImage} />;
  }
}

export class TrainPuzzle extends React.Component {
  render() {
    return <SizedImage src={trainPuzzleImage} />;
  }
}

export class TruckPuzzle extends React.Component {
  render() {
    return <SizedImage src={truckPuzzleImage} />;
  }
}
