import * as React from 'react';

import '../../components/style.scss';
import { Header } from '../../components/Header';
import { NavBar } from '../../components/NavBar';
import { Column } from '../../components/Column';
import {
  BeesAndHoney,
  Bubble,
  FireTruckPuzzle,
  MouseTutorial,
  OceanPuzzle,
  TrainPuzzle,
  TruckPuzzle,
} from '../../components/MouseSkills';
import { LinkRow } from '../../components/LinkRow';

export interface FirstGradeProps {}

export class FirstGrade extends React.Component<FirstGradeProps> {
  render() {
    return (
      <div>
        <Header title="1st Grade" />

        <NavBar page="1st-grade" />

        <div className="container-fluid button-container">
          <div className="row">
            <Column title="Mouse Skills">
              <LinkRow>
                <a href="https://www.abcmouse.com/email/mouse-and-pointer" target="_blank">
                  <MouseTutorial />
                  Mouse and Pointer Tutorial
                </a>
              </LinkRow>
              <LinkRow>
                <a href="https://www.letsgolearn.com/bubble.html" target="_blank">
                  <Bubble />
                  Mouse Practice: Pop the Bubble
                </a>
              </LinkRow>
              <LinkRow>
                <a href="https://tvokids.com/preschool/games/bees-and-honey" target="_blank">
                  <BeesAndHoney />
                  Mouse Practice: Bees and Honey
                </a>
              </LinkRow>
              <LinkRow>
                <a href="http://www.meddybemps.com/9.901b.html" target="_blank">
                  <OceanPuzzle />
                  Mouse Practice: Ocean Puzzle
                </a>
              </LinkRow>
              <LinkRow>
                <a href="http://www.meddybemps.com/9.901c.html" target="_blank">
                  <FireTruckPuzzle />
                  Mouse Practice: Firetruck Puzzle
                </a>
              </LinkRow>
              <LinkRow>
                <a href="http://www.meddybemps.com/9.901d.html" target="_blank">
                  <TrainPuzzle />
                  Mouse Practice: Train Puzzle
                </a>
              </LinkRow>
              <LinkRow>
                <a href="http://www.meddybemps.com/9.901e.html" target="_blank">
                  <TruckPuzzle />
                  Mouse Practice: Truck Puzzle
                </a>
              </LinkRow>
            </Column>
            <Column title="Typing" />
            <Column title="Google">
              <div>
                <a href="https://earth.google.com/web" target="_blank">
                  Google Earth
                </a>
              </div>
            </Column>
            <Column title="Internet Safety" />
            <Column title="Bonus" />
          </div>
        </div>
      </div>
    );
  }
}
