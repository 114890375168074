import * as React from 'react';

import '../../components/style.scss';
import { Header } from '../../components/Header';
import { NavBar } from '../../components/NavBar';
import { Column } from '../../components/Column';

export interface ThirdGradeProps {}

export class ThirdGrade extends React.Component<ThirdGradeProps> {
  render() {
    return (
      <div>
        <Header title="3rd Grade" />

        <NavBar page="3rd-grade" />

        <div className="container-fluid button-container">
          <div className="row">
            <Column title="Rules and Procedures" />
            <Column title="Typing">
              <div>
                <a href="https://magellanschool.typingclub.com/" target="_blank">
                  Typing Club
                </a>
              </div>
              <div>
                <a href="https://10fastfingers.com/typing-test/english" target="_blank">
                  Typing Test
                </a>
              </div>
            </Column>
            <Column title="Google">
              <div>
                <a
                  href="https://docs.google.com/presentation/d/18W3mSM0CG7yX4L4M4sivUsJl0HUheKm-Xk8YTJ6tWEE/copy"
                  target="_blank"
                >
                  Google Slides Intro
                </a>
              </div>
            </Column>
            <Column title="Internet Safety" />
            <Column title="Projects" />
            <Column title="Bonus" />
          </div>
        </div>
      </div>
    );
  }
}
