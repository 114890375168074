import * as React from 'react';
import styled from 'styled-components';

interface BigButtonProps {
  number: number | string;
  title: string;
}

const StyledButton = styled.button`
  border: solid black 5px;
  background-color: #e6f2fc;
  border-radius: 20px;
  color: black;
`;

const Number = styled.div`
  font-size: 200px;
  font-family: zapfchancery, 'Apple Chancery', serif;
`;

const Title = styled.div`
  font-size: 24px;
`;

export class BigButton extends React.Component<BigButtonProps> {
  render() {
    return (
      <StyledButton>
        <Number>{this.props.number}</Number>
        <Title>{this.props.title}</Title>
      </StyledButton>
    );
  }
}
